// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/icons/alert-icons/AlertTriangleFilled.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/icons/alert-icons/AlertTriangleFilled.tsx");
  import.meta.hot.lastModified = "1722523621455.827";
}
// REMIX HMR END

import { forwardRef } from 'react';
import colors from 'tailwindcss/colors.js';
const variants = {
  success: colors.green[600],
  info: colors.blue[600],
  warning: colors.amber[500],
  error: colors.red[600]
};
export const AlertTriangleFilled = forwardRef(_c = ({
  height,
  width,
  size = 20,
  variant = 'info',
  ...props
}, ref) => {
  return <svg ref={ref} width={width ?? size} height={height ?? size} viewBox='0 0 24 24' fill='none' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M11.0621 4.96686C11.5781 3.99286 12.4201 3.98886 12.9381 4.96686L20.0621 18.4249C20.5781 19.3989 20.1071 20.1959 19.0051 20.1959H4.99507C3.89307 20.1959 3.42007 19.4029 3.93807 18.4249L11.0621 4.96686ZM11.2929 14.7071C11.4804 14.8946 11.7348 15 12 15C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1054 8.48043 11 8.73478 11 9V14C11 14.2652 11.1054 14.5196 11.2929 14.7071ZM11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17C13 16.7348 12.8946 16.4804 12.7071 16.2929C12.5196 16.1054 12.2652 16 12 16C11.7348 16 11.4804 16.1054 11.2929 16.2929C11.1054 16.4804 11 16.7348 11 17C11 17.2652 11.1054 17.5196 11.2929 17.7071Z' fill={variants[variant]} />
    </svg>;
});
_c2 = AlertTriangleFilled;
var _c, _c2;
$RefreshReg$(_c, "AlertTriangleFilled$forwardRef");
$RefreshReg$(_c2, "AlertTriangleFilled");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;